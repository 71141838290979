import ArticleSection from "../components/article-section/ArticleSection"
import BlockContainer from "../components/block-container/BlockContainer"
import ContentContainer from "../components/content-container/ContentContainer"
import Footer from "../components/footer/Footer"
import { Heading } from "dnb-ui-lib/components"
import Layout from "../components/layout/Layout"
import PortableText from "@sanity/block-content-to-react"
import React from "react"
import SEO from "../components/seo/Seo"
import { graphql } from "gatsby"

const Citations = ({ data }) => {
  const citationData = data.allSanityCitations.edges[0].node
  return (
    <Layout>
      <SEO title="Kilder" />
      <ArticleSection>
        <ContentContainer>
          <BlockContainer>
            <Heading>{citationData.citationHead}</Heading>
            <PortableText blocks={citationData.citationText._rawBlockText} />
          </BlockContainer>
        </ContentContainer>
      </ArticleSection>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityCitations {
      edges {
        node {
          citationHead
          citationText {
            textAlignCenter
            _rawBlockText(resolveReferences: { maxDepth: 10 })
            blockText {
              ...BlockContent
            }
          }
        }
      }
    }
  }
`

export default Citations
