import { StaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import { PropTypes } from "prop-types"
import React from "react"

/**
 * Returns a wrapper with a background-image, using a staticQuery and filtering on assetId
 * @param {assetId} string The assetId of the image that will be used as a background
 * @param {className} string Extra styles provided from the parent
 * @param {children} object The content that will be wrapped by the background-image
 *
 */

const BackgroundByAssetId = ({ children, assetId, className }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityImageAsset {
            edges {
              node {
                assetId
                fluid(maxWidth: 2000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage
          Tag="div"
          fluid={
            data.allSanityImageAsset.edges.find(asset => {
              return asset.node.assetId === assetId
            }).node.fluid
          }
          className={className}
        >
          {children}
        </BackgroundImage>
      )}
    />
  )
}

BackgroundByAssetId.propTypes = {
  assetId: PropTypes.string.isRequired,
  className: PropTypes.string,
}

BackgroundByAssetId.defaultProps = {
  assetId: null,
  className: null,
}

export default BackgroundByAssetId
