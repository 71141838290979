import { PropTypes } from "prop-types"
import React from "react"
import VideoPlayer from "../video-player/VideoPlayer"
import classNames from "classnames"
import * as styles from "./BackgroundVideo.module.scss"

/**
 * Returns a wrapper for article content
 * @param {filter} bool Specifies if there should be added a darkening filter on the background
 *
 */

const BackgroundVideo = ({ children, filter, className, video }) => {
  return (
    <section className={classNames(styles.backgroundVideo, className)}>
      <VideoPlayer
        className={styles.video}
        asset={video.asset}
        isBackgroundVideo={true}
      />
      {children}
    </section>
  )
}

BackgroundVideo.propTypes = {
  filter: PropTypes.bool,
}

BackgroundVideo.defaultProps = {
  filter: true,
}

export default BackgroundVideo
