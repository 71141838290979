import BackgroundByAssetId from "../background-by-asset-id/BackgroundByAssetId"
import BackgroundImage from "gatsby-background-image"
import BackgroundVideo from "../background-video/BackgroundVideo"
import ScrollInstruction from "../scroll-instruction/ScrollInstruction"
import Filter from "../filter/Filter"
import { PropTypes } from "prop-types"
import React from "react"
import classNames from "classnames"
import * as styles from "./ArticleSection.module.scss"

/**
 * Returns a wrapper for article content
 * @param {scrollInstruct} bool Specifies if there should be added a animated arrow to instruct scrolling
 * @param {filter} bool Specifies if there should be added a darkening filter on the background
 * @param {filterStrength} number Specifies the strength of the filter
 * @param {backgroundImage} object An image for displaying as a background of the section
 * @param {backgroundImage} object A video for displaying as a background of the section
 * @param {theme} string Specifies the color theme to be used on background and text
 * @param {children} object The content that will be wrapped
 * @param {chain} bool Indicates if the sections should chain together with other adjecent chained sections and reduce margins
 *
 */

const ArticleSection = ({
  children,
  theme,
  backgroundImage,
  backgroundVideo,
  filter,
  filterStrength,
  chain,
  scrollInstruct,
}) => {
  if (backgroundVideo) {
    return (
      <BackgroundVideo
        className={classNames(styles.articleSection, styles.backgroundVideo, {
          [styles.chain]: chain,
        })}
        video={backgroundVideo}
      >
        {filter === true && <Filter strength={filterStrength} />}
        <div className={styles.content}>{children}</div>
        {scrollInstruct && <ScrollInstruction />}
      </BackgroundVideo>
    )
  } else if (backgroundImage) {
    if (backgroundImage.asset) {
      console.log(backgroundImage.asset)
      return (
        <BackgroundImage
          Tag="section"
          className={classNames(styles.articleSection, styles.backgroundImage, {
            [styles.chain]: chain,
          })}
          fluid={backgroundImage.asset.fluid}
        >
          {filter === true && <Filter />}
          <div className={styles.content}>{children}</div>
          {scrollInstruct && <ScrollInstruction />}
        </BackgroundImage>
      )
    } else {
      console.log(backgroundImage.assetId)
      return (
        <BackgroundByAssetId
          assetId={backgroundImage.assetId}
          className={classNames(styles.articleSection, styles.backgroundImage, {
            [styles.chain]: chain,
          })}
        >
          {filter === true && <Filter />}
          <div className={styles.content}>{children}</div>
          {scrollInstruct && <ScrollInstruction />}
        </BackgroundByAssetId>
      )
    }
  }
  return (
    <section
      className={classNames(styles.articleSection, styles[theme], {
        [styles.chain]: chain,
      })}
    >
      {children}
      {scrollInstruct && <ScrollInstruction />}
    </section>
  )
}

ArticleSection.propTypes = {
  theme: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundVideo: PropTypes.object,
  filter: PropTypes.bool,
  filterStrength: PropTypes.number,
  chain: PropTypes.bool,
  scrollInstruct: PropTypes.bool,
}

ArticleSection.defaultProps = {
  theme: null,
  backgroundImage: null,
  backgroundVideo: null,
  filter: true,
  filterStrength: null,
  chain: false,
  scrollInstruct: false,
}

export default ArticleSection
