import { PropTypes } from "prop-types"
import React from "react"
import classNames from "classnames"
import * as styles from "./BlockContainer.module.scss"

/**
 * Returns a wrapper used for Sanity portable-text
 * @param {children} object The content to be wrapped
 * @param {aside} bool Specifies if the content should be floated right
 * @param {center} bool Specifies if the content should be margin autoed
 *
 */

const BlockContainer = ({ children, aside, center }) => {
  return (
    <div
      className={classNames(
        styles.block,
        { [styles.collumn]: aside === true },
        { [styles.single]: !aside },
        { [styles.center]: center }
      )}
    >
      {children}
    </div>
  )
}

BlockContainer.propTypes = {
  aside: PropTypes.bool,
  center: PropTypes.bool,
}

BlockContainer.defaultProps = {
  article: false,
  center: false,
}

export default BlockContainer
