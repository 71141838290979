import * as styles from "./Footer.module.scss"

import { Anchor, Icon, Logo } from "dnb-ui-lib"
import { StaticQuery, graphql } from "gatsby"

import { arrow_up as ArrowUp } from "dnb-ui-lib/icons"
import React from "react"
import linkMaker from "../../utils/linkMaker"
import { navigate } from "gatsby"

/**
 * Returns a styled footer, using a StaticQuery to get data from Sanity
 *
 */

const scrollTop = () => {
  window.scrollTo(0, 0)
}

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanitySiteFooter {
            edges {
              node {
                copyrightInfo
                links {
                  _key
                  text
                  linkTo {
                    ... on SanityPageLanding {
                      _type
                    }
                    ... on SanityLinkHref {
                      _type
                      href
                    }
                    ... on SanityArticleFeature {
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityArticleBasic {
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityCitations {
                      id
                      _type
                    }
                    ... on SanityVisualiser {
                      id
                      _type
                    }
                    ... on SanityPageFond {
                      id
                      _type
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <FooterElement data={data} />}
    />
  )
}

const FooterElement = ({ data }) => {
  const footerData = data.allSanitySiteFooter.edges[0].node
  const links = footerData.links.map(({ _key, text, linkTo }) => {
    const linkData = linkMaker(linkTo[0])
    if (linkData.internal === true) {
      return (
        <Anchor
          className={"dnb-anchor--contrast"}
          onClick={() => {
            navigate(`${linkData.location}`)
          }}
          key={_key}
        >
          {text}
        </Anchor>
      )
    } else {
      return (
        <Anchor
          className={"dnb-anchor--contrast"}
          href={linkData.location}
          key={_key}
        >
          {text}
        </Anchor>
      )
    }
  })
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.links}>{links}</div>
        <div className={styles.logoContainer}>
          <Logo width="4rem" color={"white"} />
        </div>
        <button className={styles.goTopButton} onClick={scrollTop}>
          <div className={styles.goTopContainer}>
            <Icon icon={ArrowUp} size={"medium"} aria-hidden />
            Til topp
          </div>
        </button>
        <hr className={styles.divider} />
        <div className={styles.copyright}>{footerData.copyrightInfo}</div>
      </div>
    </footer>
  )
}

export default Footer
